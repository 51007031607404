<template>
  <div id="divPointToDiscountWrapper" class="convert-form">
    <VueForm ref="form">
      <section class="cards">
        <div class="cards-list-header">
          <div class="content-layout-fixer">
            <div class="cards-list-header-content">
              <VueText sizeLevel="13" weightLevel="4">{{ pointFormatter(totalPoints) }}</VueText>
            </div>
            <VueText color="grey-30" sizeLevel="10">Puan</VueText>
          </div>
        </div>
        <div class="cards-list-actions">
          <div class="content-layout-fixer">
            <VueButton
              ghost
              v-if="isVisibleGiftCatalog"
              @click="goToGiftCatalog()"
              :iconName="icons.tag.name"
              :iconSize="{ width: icons.tag.width, height: icons.tag.height }"
              :contentAlignment="alignment.center"
              class="cards-list-actions-button"
              >Hediye Al</VueButton
            >
            <VueButton
              ghost
              as="router-link"
              :target="walletDetailURL"
              :iconName="icons.detail.name"
              :iconSize="{ width: icons.detail.width, height: icons.detail.height }"
              :contentAlignment="alignment.center"
              class="cards-list-actions-button"
              >Detay</VueButton
            >
          </div>
        </div>
      </section>
      <VueWrapperNarrow>
        <section class="section cards" v-if="!selectedCard">
          <VueText sizeLevel="6" weightLevel="3" color="grey-40" class="section-headline"
            >Hangi Hediye Kartınızdan Puan Aktarmak İstersiniz?</VueText
          >
          <div :class="{ 'cards-list': true, 'cards-list-toggled': isCardsToggled }">
            <div class="cards-list-select">
              <VueButton
                :iconName="icons.wallet.name"
                :iconSize="{ width: icons.wallet.width, height: icons.wallet.height }"
                :size="sizes.xLarge"
                iconColor="#e5472d"
                class="cards-list-select-textButton"
                @click="toggleList"
              >
                <VueText sizeLevel="6" weightLevel="4" color="red-10">Kart Seçiniz</VueText>
              </VueButton>
              <VueButton
                :key="isCardsToggled"
                :isIconButton="true"
                :iconName="isCardsToggled ? icons.chevronUpAlt.name : icons.chevronDownAlt.name"
                :size="sizes.xLarge"
                :iconSize="{
                  width: icons.chevronDownAlt.width,
                  height: icons.chevronDownAlt.height,
                }"
                iconColor="#e5472d"
                class="cards-list-select-downButton"
                @click="toggleList"
              >
              </VueButton>
            </div>
            <div class="cards-list-dropdown">
              <div class="cards-list-item" v-for="card in customerDetail.partners" :key="card.code">
                <VueButton
                  ghost
                  :rightIcon="card.point > 0 ? icons.right : null"
                  :disabled="card.point > 0 ? false : true"
                  class="cards-list-button"
                  @click="selectCard(card)"
                >
                  <div
                    class="cards-list-image"
                    :style="{
                      backgroundImage: `url(${card.image})`,
                    }"
                  ></div>
                  <div v-if="card.point > 0" class="cards-list-text">
                    <VueText sizeLevel="12" weightLevel="4">{{
                      pointFormatter(card.point)
                    }}</VueText>
                    <div class="cards-list-text-point">
                      <VueText color="grey-30" sizeLevel="5">Puan</VueText>
                    </div>
                  </div>
                  <div v-else class="cards-list-text">
                    <VueText sizeLevel="4" weightLevel="4">{{
                      `${card.desc} için indirime dönüştürülebilir puanınız bulunmamaktadır.
`
                    }}</VueText>
                  </div>
                </VueButton>
              </div>
            </div>
          </div>
          <BrandButton
            :size="sizes.xxLarge"
            :outlined="true"
            class="convert-button"
            type="submit"
            :padding="{ vertical: 15, horizontal: 0 }"
            @click="handleBack"
            ><VueText weightLevel="3" color="red-10">GERİ DÖN</VueText></BrandButton
          >
        </section>
        <section class="section cards" v-if="selectedCard">
          <VueHeadline level="4" weightLevel="3" color="grey-40" class="section-headline"
            >İndirim Kartım</VueHeadline
          >
          <div :class="{ 'cards-list': true, 'cards-list-toggled': isCardsToggled }">
            <div class="cards-list-select cards-list-selected">
              <VueButton :size="sizes.xLarge" class="cards-list-selectedCard">
                <div
                  class="cards-list-image"
                  :style="{
                    backgroundImage: `url(${selectedCard.image})`,
                  }"
                >
                  <VueIcon
                    :width="icons.checked.width"
                    :height="icons.checked.height"
                    :iconName="icons.checked.name"
                  />
                </div>
                <VueText class="card-desc" sizeLevel="5" color="#23303d">{{
                  selectedCard.desc
                }}</VueText>
                <div class="cards-list-select-textButton_rightText">
                  <VueText sizeLevel="9" weightLevel="3">{{
                    pointFormatter(selectedCard.amount)
                  }}</VueText
                  ><VueText color="grey-30" sizeLevel="6">TL</VueText>
                </div>
              </VueButton>
            </div>
          </div>
          <BrandButton
            class="gift-card-button"
            @click="convertAllScore"
            iconColor="#fff"
            :iconName="icons.refresh.name"
            :iconSize="{ width: icons.refresh.width, height: icons.refresh.height }"
          >
            <VueText color="white-100" sizeLevel="6" weightLevel="2">Tüm Puanımı Dönüştür</VueText>
          </BrandButton>
          <div class="cards-convert">
            <VueHeadline level="4" weightLevel="3" color="grey-40" class="section-headline"
              >Çevrilecek Puan Miktarını Giriniz
              <VueIcon
                :width="icons.refresh.width"
                :height="icons.refresh.height"
                :iconName="icons.refresh.name"
              />
            </VueHeadline>
            <!-- <div class="cards-convert-input">
              <input
                v-model="convertingValue"
                type="number"
                :placeholder="`Maksimum ${selectedCard.maxConvertableValue} Puan Çevrilebilir`"
              />
              <VueIcon
                :width="icons.info.width"
                :height="icons.info.height"
                :iconName="icons.info.name"
                color="#79838e"
              />
            </div> -->
            <VueInput
              type="number"
              class="cards-convert-input"
              name="convertingValue"
              id="convertingValue"
              validatorName="Puan Miktarı"
              :label="`Maksimum ${pointFormatter(totalPoints)} Puan Çevrilebilir`"
              v-model="convertingValue"
              :messageOnTop="true"
              ref="convertingValue"
              :validationRules="`required|numeric|between:1,${totalPoints}`"
            >
              <span class="icon-span">
                <VueIcon
                  :width="icons.info.width"
                  :height="icons.info.height"
                  :iconName="icons.info.name"
                  color="#79838e"
                />
                <div class="icon-info-popup">
                  <div class="rule-container" v-for="(rule, i) in pointRules" :key="`rule_${i}`">
                    <p class="rule-title">{{ rule.title }}</p>
                    <p class="rule-desc">{{ rule.desc }}</p>
                  </div>
                </div>
              </span>
            </VueInput>
            <div class="cards-convert-quantity">
              <VueText color="grey-30" sizeLevel="5">Çevrilecek Miktar</VueText>
              <div class="cards-convert-quantity-value">
                <VueText sizeLevel="9" weightLevel="3">{{
                  pointFormatter(getConvertingQuantity)
                }}</VueText
                ><VueText color="grey-30" sizeLevel="6">TL</VueText>
              </div>
            </div>
          </div>
          <BrandButton
            v-if="selectedCard"
            :size="sizes.xxLarge"
            class="convert-button"
            type="submit"
            :padding="{ vertical: 15, horizontal: 0 }"
            @click.prevent="submitConvert"
            ><VueText weightLevel="3" color="white-100">DEVAM ET</VueText></BrandButton
          >
          <BrandButton
            :size="sizes.xxLarge"
            :outlined="true"
            class="convert-button"
            type="submit"
            :padding="{ vertical: 15, horizontal: 0 }"
            @click="emptySelectedCard"
            ><VueText weightLevel="3" color="red-10">GERİ DÖN</VueText></BrandButton
          >
        </section>
      </VueWrapperNarrow>
    </VueForm>
    <BrandConvertSuccessView
      :showComponent="showSuccessView"
      :amount="pointFormatter(getConvertingQuantity)"
      @close="closeSuccessModal"
    ></BrandConvertSuccessView>
    <div class="custom-modal" v-if="showModal">
      <div class="custom-modal-header">
        <a class="close-icon" @click="closeModal">
          <VueIcon iconName="IconCross" iconColor="#FFFFFF" />
        </a>
      </div>
      <div class="custom-modal-content">
        <div class="info-container">
          <p class="info">
            <b>{{ pointFormatter(convertingValue) }}</b> Puanınız,
            <b>{{ pointFormatter(getConvertingQuantity) }}</b> TL olarak
            {{ selectedCard.desc }} İndirim Cüzdanınıza eklenecektir.
          </p>
          <p class="info-approve">Onaylıyor musunuz?</p>
        </div>
        <div class="modal-buttons">
          <div class="gradient-bg"><div class="cancel" @click="closeModal">İptal</div></div>
          <div class="gradient-bg">
            <div class="approve" @click="convertPoints(false)">Evet</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText';
import VueButton from '@/components/shared/VueButton/VueButton';
import VueIcon from '@/components/shared/VueIcon/VueIcon';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline';
import BrandButton from '@/components/brand/BrandButton/BrandButton';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import BrandConvertSuccessView from '@/components/brand/BrandConvertSuccessView/BrandConvertSuccessView';
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';
import { Common } from '@/services/Api/index';
import { ICON_VARIABLES, COMPONENT_SIZES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import { Finance } from '@/services/Api/index.js';
import StorageProps from '@/mixins/storageProps.js';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesMyWallets from '@/router/routes/secure/RoutesMyWallets';
import RoutesRoot from '@/router/routes/RoutesRoot';
import { numberFormatter } from '@/utils/publicHelper.js';
import StorageHelper from '@/utils/storageHelper';
import gtmUtils from '@/mixins/gtmUtils.js';

export default {
  name: 'ConverScore',
  mixins: [StorageProps, gtmUtils],
  components: {
    VueText,
    VueButton,
    VueIcon,
    VueHeadline,
    BrandButton,
    VueForm,
    VueInput,
    VueWrapperNarrow,
    BrandConvertSuccessView,
  },
  beforeMount() {
    Finance.getPointDiscountRate().then(res => {
      const {
        Data: { pointPMDiscountRate },
      } = res.data;
      this.discountRate = pointPMDiscountRate;
    });
    if (this.$route.query && this.$route.query.code) {
      let card = this.customerDetail.partners.find(c => c.code === this.$route.query.code);
      this.selectCard(card);
    }
  },
  data() {
    return {
      discountRate: 0,
      walletsHomeURL: `${RoutesRoot.Secure.path}/${RoutesSecure.MyWallets.path}/${RoutesMyWallets.WalletsHome.path}`,
      walletDetailURL: `${RoutesRoot.Secure.path}/${RoutesSecure.MyWallets.path}/${RoutesMyWallets.WalletsDetail.path}`,
      convertingValue: null,
      selectedCard: null,
      isCardsToggled: false,
      showSuccessView: false,
      showModal: false,
      pointRules: '',
    };
  },
  methods: {
    convertAllScore() {
      const modalConfig = {
        isModalOpened: true,
        modalText: `<div>Bütün puanınızı(<b>${this.pointFormatter(
          this.totalPoints,
        )}</b>) dönüştürmeyi onaylıyor musunuz?</div>`,
        isDoubleButton: true,
        firstButtonText: 'EVET',
        secondButtonText: 'İPTAL',
        modalConfirmationText: '',
        alertType: 'error',
        firstButtonFn: () => {
          this.convertPoints(true);
        },
      };
      this.$store.dispatch('app/setModalConfig', modalConfig);
    },
    handleBack() {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('cuzdanlarim', {
        location: 'indirime_donustur',
      });
      this.$router.push(this.walletsHomeURL);
    },
    goToGiftCatalog() {
      Common.getGiftCatalogLink().then(res => {
        const {
          Data: { url },
        } = res.data;
        window.location = url;
      });
    },
    toggleList() {
      this.isCardsToggled = !this.isCardsToggled;
    },
    selectCard(card) {
      if (card && card.desc) {
        this.selectedCard = card;
        Finance.getPointRules(this.selectedCard.code).then(res => {
          if (res.data && res.data.Data && res.data.Data.pointRules) {
            this.pointRules = res.data.Data.pointRules.map((item, index) => ({
              title: `Puan Kuralı ${index + 1}`,
              desc: item,
            }));
          }
        });
      }
    },
    closeSuccessModal() {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('cuzdanlarim', {
        location: 'indirime_donustur_popup',
      });
      this.$router.push(this.walletsHomeURL);
      document.body.style.overflow = 'auto';
    },
    emptySelectedCard() {
      this.selectedCard = null;
      // If user wants to select another card, the dropdown comes toggled
      this.isCardsToggled = true;
    },
    async convertPoints(isConvertAllScore) {
      this.convertingValue = isConvertAllScore ? this.totalPoints.toString() : this.convertingValue;
      Finance.convertPointToDiscount(this.selectedCard.code, +this.convertingValue).then(res => {
        if (res.data && res.data.Data.result && res.data.Data.result.status == 1) {
          this.showSuccessView = true;
        }
        this.$store.dispatch('app/setDoWinCardRefresh', true);
        this.showModal = false;
      });
    },
    async submitConvert() {
      const isValid = await this.$refs.form.$refs.observer.validate();
      if (!isValid) {
        //form valid değil ise
        return;
      } else {
        this.showModal = true;
        document.body.style.overflow = 'hidden';
      }
    },
    pointFormatter(value) {
      return numberFormatter(value);
    },
    closeModal() {
      this.showModal = false;
      document.body.style.overflow = 'auto';
    },
  },
  computed: {
    getMenuItems() {
      return new StorageHelper({
        id: process.env.VUE_APP_STORAGE_MENU_ITEMS,
      }).get();
    },

    isVisibleGiftCatalog() {
      return this.getMenuItems.menuItems.filter(f => f.screenCode === 7).length > 0;
    },
    totalPoints() {
      if (this.selectedCard && this.selectedCard.desc && this.selectedCard.point > 0) {
        return this.selectedCard.point;
      } else {
        return this.customerDetail.partners.reduce((a, b) => {
          return a + b['point'];
        }, 0);
      }
    },
    sizes() {
      return COMPONENT_SIZES;
    },
    icons() {
      return ICON_VARIABLES;
    },
    alignment() {
      return FLEX_JUSTIFIES;
    },
    getConvertingQuantity() {
      if (this.convertingValue <= this.totalPoints) return this.convertingValue * this.discountRate;
      else return 0;
    },
  },
};
</script>
<style scoped lang="scss">
.my-wallets {
  padding: palette-space-level('30') 0 0 0;
}

.section {
  &-headline {
    margin-bottom: palette-space-level('20');
  }
}

.cards {
  &-list {
    $self: &;
    &-header {
      height: 130px;
      border-top: 1px solid palette-color-level('grey', '15');
      border-bottom: 1px solid palette-color-level('grey', '15');
      border-right: 1px solid palette-color-level('grey', '15');
      border-top-right-radius: palette-radius-level('2');
      background-color: palette-color-level('grey', '10');
      margin-top: palette-space-level('50');
      padding: palette-space-level('30') 0;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;

      &--no-action {
        border-bottom-right-radius: palette-radius-level('2');
        margin-bottom: palette-space-level('50');
      }
      & > .content-layout-fixer {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        p {
          padding: palette-space-level('10');
        }
        & > p {
          padding-bottom: 0;
        }
      }

      &-content {
        display: flex;
        align-items: center;
        background: url('~@/assets/images/score.png') no-repeat left center;
        background-size: contain;
        padding-left: palette-space-level('60');
        height: 40px;

        p:last-child {
          margin-left: palette-space-level('5');
        }
      }
    }

    &-select {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100px;
      border-radius: palette-radius-level('2');
      background-image: linear-gradient(
        315deg,
        palette-color-level('fushia', '20'),
        palette-color-level('yellow', '30')
      );
      overflow: hidden;
      margin: 0 auto;

      &-textButton,
      &-downButton {
        margin: 0 0 0 2px;
        height: calc(100% - 4px);
        display: flex;
        align-items: center;
        background-color: palette-color-level('white', '100');
        border: none !important;
      }
      &-textButton {
        flex: 0 0 calc(100% - 102px);
        padding-left: palette-space-level('20') !important;
        border-radius: palette-radius-level('1');
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        &_rightText {
          margin-left: auto;
          display: flex;
          align-items: center;

          p:first-child {
            padding-left: palette-space-level('8');
          }
          p:last-child {
            margin-left: palette-space-level(5);
          }
        }
      }
      &-downButton {
        flex: 0 0 96px;
        margin-right: 2px;
        justify-content: center;
        border-radius: palette-radius-level('1');
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        span {
          margin-right: 0 !important;
        }
      }
    }
    &-selected {
      height: 150px;
      border-radius: 4px !important;
      button {
        width: calc(100% - 4px);
        margin: auto;
        background: #ffffff;
        height: calc(100% - 4px);
        border: none;
        border-radius: 2px !important;
      }
    }
    &-actions {
      margin-bottom: palette-space-level('50');

      & > .content-layout-fixer {
        display: flex;
        justify-content: center;
      }

      &-button {
        flex-basis: 50%;
        display: flex;
        align-items: center;
        padding: palette-space-level('25') palette-space-level('10') !important;
        font-size: palette-font-size-level('6');
        font-weight: palette-font-weight-level('3');

        &,
        &:hover {
          border-bottom: 1px solid palette-color-level('grey', '15') !important;
        }

        &:first-child,
        &:first-child:hover {
          border-left: 1px solid palette-color-level('grey', '15') !important;
          border-right: 1px solid palette-color-level('grey', '15') !important;
          border-bottom-left-radius: palette-radius-level('2') !important;
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }

        &:last-child,
        &:last-child:hover {
          border-right: 1px solid palette-color-level('grey', '15') !important;
          border-bottom-right-radius: palette-radius-level('2') !important;
        }
      }
    }
    &-toggled {
      #{$self} {
        &-dropdown {
          height: auto;
          border: 1px solid palette-color-level('grey', '15');
        }
        &-select {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          margin-bottom: palette-space-level('20');
          &-textButton {
            border-bottom-left-radius: 0;
          }
          &-downButton {
            border-bottom-right-radius: 0;
          }
        }
      }
    }

    &-item {
      margin-top: 2px;
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        border-bottom: 1px solid palette-color-level('grey', '15');
      }

      button {
        padding: palette-space-level('10') palette-space-level('20');
        border-top-left-radius: 0;
      }
    }
    &-dropdown {
      height: 0;
      overflow: hidden;
      border-bottom-left-radius: palette-radius-level('3');
      border-bottom-right-radius: palette-radius-level('3');
      border: none;
      border-radius: palette-radius-level('2');

      margin: 0 auto palette-space-level('30') auto;
    }

    &-button {
      width: 100%;
      padding: palette-space-level('20') !important;

      &:not(:first-child) {
        &,
        &:hover {
          border-top: 1px solid palette-color-level('grey', '15') !important;
          border-radius: 0px !important;
        }
      }
    }
    &-image {
      position: relative;
      background-color: palette-color-level('white', '100');
      background-repeat: no-repeat;
      background-position: center center;
      min-width: palette-space-level('100');
      height: palette-space-level('100');
      border: 1px solid palette-color-level('grey', '20');
      border-radius: palette-radius-level('3');
      background-size: 90%;
      svg {
        position: absolute;
        top: -8px;
        right: -10px;
      }
    }
    /deep/ &-text {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 0 auto;
      &-point {
        margin-left: 20px;
      }
      & + span {
        margin-left: 0;
      }
    }
    &-selectedCard {
      pointer-events: none;
      background-color: palette-color-level('white', '100');
      border: none;
      border-radius: palette-radius-level('3');
      padding: palette-space-level('10') palette-space-level('20');
      .card-desc {
        margin-left: palette-space-level('20');
      }
    }
  }

  &-convert {
    margin-top: palette-space-level('30');

    .section-headline {
      position: relative;
      padding-right: palette-space-level('40');

      svg {
        position: absolute;
        right: palette-space-level('20');
        top: 2px;
      }
    }

    &-quantity {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 74px;
      border: 1px solid palette-color-level('grey', '30');
      border-radius: palette-radius-level('2');
      padding: palette-space-level('10') palette-space-level('20');

      &-value {
        display: flex;
        align-items: center;
        padding-left: palette-space-level('20');

        p:last-child {
          margin-left: palette-space-level('5');
          align-self: initial;
        }
      }
    }

    /deep/ &-input {
      width: 100%;
      font-size: palette-font-size-level('5');
      color: palette-color-level('grey', '40');
      font-family: $font-family;
      letter-spacing: 0;
      margin-bottom: palette-space-level('20');
      input {
        padding-right: palette-space-level('50');
        padding-top: palette-space-level('20');
      }
      label {
        font-size: palette-font-size-level('6');
      }

      &::placeholder {
        color: palette-color-level('grey', '20');
        letter-spacing: normal;
      }
    }
    &-input,
    &-quantity {
      position: relative;

      .icon-span {
        position: absolute;
        right: palette-space-level('20');
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
// Global modal yapıldığında güncellenecek
.custom-modal {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9998;
  top: 0;
  left: 0;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background: rgba(0, 0, 0, 0.8);
  .custom-modal-header {
    .close-icon {
      position: absolute;
      z-index: 9999;
      right: 28px;
      top: 28px;
      cursor: pointer;
    }
  }
  .custom-modal-content {
    position: relative;
    display: block;
    width: 450px;
    background: #ffffff;
    font-size: palette-font-size-level('12');
    margin: 50vh auto 0;
    transform: translateY(-50%);
    border-radius: palette-radius-level('2');

    .custom-modal-title {
      padding: palette-space-level('30');
      text-align: center;
    }
    hr {
      border-top: 0.5px solid transparentize(palette-color-level('grey', 20), 0.5);
    }
    .info-container {
      text-align: center;
      padding: palette-space-level('45');

      .info-approve {
        text-align: center;
        padding-top: palette-space-level('40');
        font-weight: palette-font-weight-level('3');
      }
    }
    .modal-buttons {
      display: flex;
      width: 100%;

      & .gradient-bg {
        padding: 1px;
        text-align: center;
        flex: 1 1 0;

        background-image: linear-gradient(
          315deg,
          palette-color-level('fushia', '20'),
          palette-color-level('yellow', '30')
        );
        & > div {
          padding: palette-space-level('30');
          font-weight: palette-font-weight-level('3');
          cursor: pointer;
        }
        & .approve {
          color: #fff;
          background: transparent;
        }
        & .cancel {
          background: palette-color('white-100');
        }
      }
    }
  }
}
</style>
<style lang="scss">
.icon-span {
  position: relative;
  cursor: pointer;
  &:hover {
    z-index: 10;
  }
  &:hover .icon-info-popup {
    display: block;
  }
  .icon-info-popup {
    display: none;
    position: absolute;
    top: palette-space-level('30');
    right: 50%;
    transform: translateX(50%);
    z-index: 9990;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
    background: #ffffff;
    padding: palette-space-level('20');
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(black, 0.1);
    width: 300px;
    border-radius: palette-radius-level('2');
    .rule-container {
      &:not(:first-child) {
        margin-top: palette-space-level('10');
      }
      .rule-title {
        font-size: palette-font-size-level('6');
        line-height: 1.2;
        font-weight: palette-font-weight-level('3');
      }
      .rule-desc {
        font-size: palette-font-size-level('4');
        line-height: 1.4;
      }
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.convert-button {
  margin-top: palette-space-level('30');
  &:last-child {
    margin-top: palette-space-level('15');
  }
}
</style>
